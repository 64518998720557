<template>
  <div id="FinanceScreen">
    <v-container>
      <v-row>
        <v-col class="col-12 col-lg-6">
          <v-widget title="Kas Kecil" content-bg="white">
            <div slot="widget-content">
              <div>Bulan {{ currentMonth }}</div>
              <e-chart
                v-if="isKasKecilLoaded"
                :path-option="[
                  ['dataset.source', graphicFinancesData.cash],
                  ['legend.bottom', '0'],
                  ['color', ['#F24848', '#335EA6']],
                  ['xAxis.show', false],
                  ['yAxis.show', false],
                  ['series[0].type', 'pie'],
                  ['series[0].avoidLabelOverlap', true],
                  ['series[0].radius', ['0', '70%']],
                  ['series[0].label', false]
                ]"
                height="200px"
                width="100%"
              >
              </e-chart>
            </div>
          </v-widget>
        </v-col>
        <v-col class="col-12 col-lg-6">
          <v-widget title="Bank" content-bg="white">
            <div slot="widget-content">
              <div>Bulan {{ currentMonth }}</div>
              <e-chart
                v-if="isBankLoaded"
                :path-option="[
                  ['dataset.source', graphicFinancesData.bank],
                  ['legend.bottom', '0'],
                  ['color', ['#F24848', '#335EA6']],
                  ['xAxis.show', false],
                  ['yAxis.show', false],
                  ['series[0].type', 'pie'],
                  ['series[0].avoidLabelOverlap', true],
                  ['series[0].radius', ['0', '70%']],
                  ['series[0].label', false]
                ]"
                height="200px"
                width="100%"
              >
              </e-chart>
            </div>
          </v-widget>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="col-12 col-lg-9">
          <finance-list></finance-list>
        </v-col>

        <v-col class="col-12 col-lg-3">
          <v-card>
            <v-app-bar dense color="white">
              <v-toolbar-title>
                <h5>Masukkan Pencarian</h5>
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-menu
                  v-model="menuFromDate"
                  :close-on-content-click="false"
                  :nudge-top="20"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="fromDate"
                      label="Dari Tanggal"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    @input="menuFromDate = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="menuToDate"
                  :close-on-content-click="false"
                  :nudge-top="20"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="toDate"
                      label="Sampai Tanggal"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    @input="menuToDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                block
                color="primary"
                @click="getFinanceBySearch"
                :loading="loading"
                :disabled="loadingStatus"
                >Cari</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VWidget from "@/components/VWidget";
import EChart from "@/plugins/echart";
import FinanceList from "@/components/Widget/mitra/FinanceList";
import globalMixin from "@/mixins/globalMixin.js";

export default {
  components: {
    VWidget,
    EChart,
    FinanceList
  },
  mixins: [globalMixin],

  data() {
    return {
      fromDate: "",
      toDate: "",
      menuFromDate: false,
      menuToDate: false,
      isBankLoaded: false,
      isKasKecilLoaded: false,
      loading: false,
      valid: true,
      cash: [
        {
          nominal: "Pengeluaran",
          value: 0
        },
        {
          nominal: "Pemasukan",
          value: 0
        }
      ],
      bank: [
        {
          nominal: "Pengeluaran",
          value: 0
        },
        {
          nominal: "Pemasukan",
          value: 0
        }
      ]
    };
  },

  mounted() {
    this.getCurrentMonth();
    this.getFinanceGraphic();
    this.$store.dispatch("finance/getAll");
  },

  computed: {
    loadingStatus() {
      return this.$store.getters.getLoading;
    },
    graphicFinancesData() {
      return this.$store.getters["finance/getGraphic"];
    }
  },

  methods: {
    getFinanceGraphic() {
      this.$store
        .dispatch("finance/getFinanceGraphic")
        .then(() => {
          this.isKasKecilLoaded = true;
          this.isBankLoaded = true;
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
          this.isKasKecilLoaded = false;
          this.isBankLoaded = false;
        });
    },

    getFinanceBySearch() {
      this.loading = true;

      let params = {
        start_date: this.fromDate,
        end_date: this.toDate
      };

      this.$store
        .dispatch("finance/getBySearch", params)
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
