<template>
  <v-card>
    <v-app-bar dense color="transparent">
      <v-toolbar-title>
        <h5>Arus Kas</h5>
      </v-toolbar-title>
      <v-spacer />
      <v-icon @click="refreshData">mdi-refresh</v-icon>
    </v-app-bar>

    <v-card-text class="pa-0">
      <template>
        <v-data-table
          :headers="headers"
          :items="financesData"
          :loading="loadingStatus"
          loading-text="Sedang Menyiapkan Data..."
          mobile-breakpoint="0"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.notes }}</td>
              <td>
                <v-chip :color="getColor(props.item.flag_in_out)" dark>{{
                  formatRupiah(props.item.amount)
                }}</v-chip>
              </td>
              <td>
                <v-chip
                  :color="getMethodColor(props.item.transaction_method_id)"
                  dark
                  >{{ props.item.payment_method }}</v-chip
                >
              </td>
              <td>{{ getDate(props.item.timestamp) }}</td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";

export default {
  mixins: [globalMixin],

  data() {
    return {
      headers: [
        { text: "Catatan", sortable: false },
        { text: "Total", sortable: false },
        { text: "Metode Pembayaran", sortable: false },
        { text: "Tanggal", sortable: false }
      ],
      methodColor: ["disabled", "#432575", "#00AA13", "#E62B1E", "primary"]
    };
  },

  computed: {
    loadingStatus() {
      return this.$store.getters.getLoading;
    },
    financesData() {
      return this.$store.getters["finance/getFinances"];
    }
  },

  methods: {
    getColor(flag) {
      if (flag === 1) return "success";
      else return "error";
    },
    refreshData() {
      this.$store.dispatch("finance/getAll");
    },
    getMethodColor(flag) {
      return this.methodColor[flag - 1];
    }
  }
};
</script>
